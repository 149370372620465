/* reset font */
@charset "utf-8";
html.hidden {
  overflow: hidden;
}
body.hidden {
  height: auto;
  overflow: hidden;
}
body {
  overflow: overlay;
}

/* fonts */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

/* reset */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  color: #ffffff;
  -webkit-tap-highlight-color: none;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body,
html {
  font-family: "Pretendard", sans-serif;
  min-height: 100%;
  font-weight: 300;
  /* background-color: #000; */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  letter-spacing: normal;
}

fieldset,
img,
abbr,
acronym {
  border: 0 none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0 none;
}
caption,
th,
td {
  text-align: left;
  font-weight: 300;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
img,
video {
  border: 0;
}
audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}
img {
  display: block;
  image-rendering: -moz-auto; /*firefox*/
  image-rendering: -o-auto; /*opera*/
  image-rendering: -webkit-optimize-contrast; /*webkit*/
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  -webkit-perspective: 1;
}
ul,
ol,
dl {
  list-style: none;
}
a {
  display: block;
  text-decoration: none;
  color: inherit;
}
i {
  display: block;
  font-style: normal;
  font-weight: 300;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
input,
select,
label {
  display: block;
  vertical-align: middle;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  /* vertical-align: middle; */
  font-weight: 300;
  letter-spacing: normal;
  background: none;
  font-family: "Pretendard", sans-serif;
}
button,
input {
  line-height: normal;
  box-shadow: none;
}
input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
button,
select {
  text-transform: none;
  cursor: pointer;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: none;
}
address {
  font-style: normal;
}
.clear::after {
  content: "";
  display: block;
  clear: both;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #383838;
  border-radius: 999px;
  background-clip: padding-box;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

@font-face {
  font-family: "DS-DIGI";
  font-weight: 400;
  src: url("./resources/font/DS-DIGI.TTF") format("truetype");
}

.digital {
  font-family: "DS-DIGI", sans-serif;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
